import React from "react";

interface IFormErrorProps {
  errorMessage: string;
}

export default function FormError({ errorMessage }: IFormErrorProps) {


  return (<span className="text-red-600 font-medium ">{errorMessage}</span>)
}

