import React, { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";

import { GetServerSidePropsContext, NextPage } from "next";
import { useRouter } from "next/router";

import { getCsrfToken, getSession, signIn, useSession } from "next-auth/react";

import { useForm } from "react-hook-form";

import Link from "next/link";
import { Layout } from "@components/common";
import FormError from "@components/ui/FormError";
import Button from "@components/ui/Button";

interface ILoginForm {
  email: string;
  password: string;
}

interface ISignIn {
  csrfToken: any;
  session: any;
}

// const Login: NextPage = () => {
export default function SignIn({ csrfToken, session }: ISignIn) {
  const {
    register,
    getValues,
    formState: { errors, isValid },
    handleSubmit,
    setError,
  } = useForm<ILoginForm>({ mode: "onChange", shouldFocusError: true });



  const router = useRouter();


  const [formError, setFormError] = useState("");

  const callbackUrl = React.useMemo(
    () =>
      typeof router.query.callbackUrl == "string"
        ? router.query.callbackUrl
        : router.query.callbackUrl?.[0] ?? null,
    [router]
  );
  if (session) {
    typeof window !== "undefined" && router.replace(`/${callbackUrl}` ?? "/");
  }

  // console.log("callbackUrl", callbackUrl);
  // console.log("callbackUrl", router.query.callbackUrl);
  // console.log("callbackUrl", router.query);

  const { status } = useSession();

  useEffect(() => {
    if (status === "authenticated") {
      router.replace(callbackUrl ? `/${callbackUrl}` : "/");
    }
  }, [callbackUrl, router, status])


  const onSubmit = async () => {
    const { email, password } = getValues();
    // @ts-ignore:next-line
    const { ok, error, status } = await signIn("credentials", {
      email,
      password,
      redirect: false,
    });

    // console.log("status", status)

    //   export interface SignInResponse {
    //     error: string | undefined;
    //     status: number;
    //     ok: boolean;
    //     url: string | null;
    // }



    if (ok) {
      const pushRouter = callbackUrl ? callbackUrl : "/";

      router.push(pushRouter);

    } else if (error) {

      setError("password", { message: error });
    }
  };

  //   const onSubmit = () => {
  //     if (!loading) {
  //       const { email, password } = getValues();
  //       loginMutation({
  //         variables: {
  //           input: {
  //             email,
  //             password,
  //           },
  //         },
  //       });
  //     }
  //   };
  //   const onCompleted = (data: loginMutation) => {
  //     const {
  //       login: { error, ok, token },
  //     } = data;
  //     if (ok && token) {
  //       // localStorage.setItem(LOCALSTORAGE_TOKEN, token);
  //       // authToken(token);
  //       // isLoggedInVar(true);
  //       router.push("/");
  //     } else {
  //       console.log(error);
  //     }
  //   };
  //   const [loginMutation, { data: loginMutationResult, loading, error }] =
  //     useMutation<loginMutation, loginMutationVariables>(LOGIN_MUTATION, {
  //       onCompleted,
  //     });

  //   if (status === "loading") {
  //     return <h1>Loading...</h1>;
  //   }

  //   if (status === "authenticated") {
  //     router.replace(callbackUrl ?? "/");
  //     return;
  //   }

  //   useEffect(() => {
  //     if (status === "authenticated") {
  //       router.replace(callbackUrl ?? "/");
  //       return;
  //     }
  //   }, [status, callbackUrl, router]);


  return (
    <div>
      <div className=" flex items-center justify-center  ">
        <div>
          <div className="bg-red-500">
            {/* {errors ? JSON.stringify(errors) : null} */}
          </div>
        </div>
        {/* <Helmet>
<title>Login | Vestiyer</title>
</Helmet> */}
        <div className="bg-white w-full max-w-lg pt-10 pb-7 rounded-lg text-start text-sm">
          <h3 className="text-3xl text-gray-800 text-center py-8">
            Vestiyer Üye Girişi
          </h3>


          <form
            onSubmit={handleSubmit(onSubmit)}
            className="grid gap-3 mt-1  px-5 mb-3"
          // method="post"
          // action="/api/auth/callback/credentials"
          >
            <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
            <div className="w-full   relative h-[56px] ">
              <input
                {...register("email", {
                  required: "Email adresi zorunludur",
                  minLength: {
                    value: 4,
                    message: "Girdiğiniz email adresi geçersiz"
                  },
                  pattern: {
                    value:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Girdiğiniz email adresi geçersiz",
                  },
                })}
                className="peer h-full placeholder-transparent focus:outline-none text-[17px] text-[#333]
    w-full px-4 pt-[18px] pb-0 border border-[#d6d6d6] rounded-sm  
   focus:ring-2 ring-[#333]
    "
                placeholder="email"
                id="email"
              />
              <label
                htmlFor="email"
                className="absolute left-[17px]  top-[.58824rem] text-[#666] text-xs transition-all
peer-placeholder-shown:text-base peer-placeholder-shown:text-[#888] peer-placeholder-shown:top-[1.05882rem]
peer-focus:top-[.58824rem] peer-focus:text-[#666] peer-focus:text-xs"
              >
                Email adresiniz
              </label>
            </div>
            {errors.email?.message && (
              <FormError errorMessage={errors.email?.message} />
            )}
            <div className="w-full   relative h-[56px] ">
              <input
                {...register("password", {
                  required: "Lütfen şifrenizi giriniz",
                  minLength: {
                    value: 8,
                    message: "Şifreniz en az 8 karakterli olmalıdır",
                  },
                })}
                placeholder="password"
                className="peer h-full placeholder-transparent focus:outline-none text-[17px] text-[#333]
      w-full px-4 pt-[18px] pb-0 border border-[#d6d6d6] rounded-sm  
     focus:ring-2 ring-[#333]
      "
                type="password"
                id="password"
              ></input>

              <label
                htmlFor="password"
                className="absolute left-[17px]  top-[.58824rem] text-[#666] text-xs transition-all
peer-placeholder-shown:text-base peer-placeholder-shown:text-[#888] peer-placeholder-shown:top-[1.05882rem]
peer-focus:top-[.58824rem] peer-focus:text-[#666] peer-focus:text-xs"
              >
                Şifre
              </label>
            </div>
            {errors.password?.message && (
              <FormError errorMessage={errors.password?.message} />
            )}
            <span className="text-xs">Oturum açarak Vestiyer&apos;ın
              <Link
                href={"/gizlilikpolitikasi"}
              >
                <a className="underline"> Gizlilik Politikası </a>
              </Link>
              &apos;nı ve

              <Link
                href={"/kullanim-sartlari"}
              >
                <a className="underline"> Kullanım Şartları</a>
              </Link>
              &apos;nı kabul etmiş olursun.</span>
            <Button
              canClick={isValid}
              loading={false}
              actionText={"Giriş Yap"}
            />

          </form>
          <div className="px-5 py-4 ">
            <Link href="/register"><a>Vestiyer&apos;e üye değil misin? <span className="underline">Bize Katıl</span> </a></Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  //   const { data: session, status } = await getSession(context);
  const session = await getSession(context)

  return {
    props: {
      csrfToken: (await getCsrfToken(context)) || null,
      // session: await getSession(context),
      session
    },
  };
}

SignIn.Layout = Layout;